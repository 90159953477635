import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/customlayout"
import SEO from "../../../components/seo"
import AirwayHeader from "../../../components/airwayheader"

import styled from "styled-components"

export default function Indications(props) {
  return (
    <>
      <AirwayHeader></AirwayHeader>
      <AirwayContainer>
        <Layout
          title={
            "Indications and Contraindications to Rapid Sequence Intubation and Induction"
          }
        >
          <SEO
            title={
              "Indications and Contraindications to Rapid Sequence Intubation and Induction"
            }
            description={
              "Rapid sequence intubation is an airway management technique that creates the optimal conditions the clinician needs for intubation."
            }
            url={"https://www.nucleotype.com/airway/rsi-indications"}
          />

          <p>
            Rapid sequence intubation is an airway management technique that
            creates the optimal conditions the clinician needs for intubation.
            In other words, it’s a way to quickly sedate and paralyze a person
            in order to perform endotracheal intubation or other airway
            management strategies.
          </p>

          <h2>The ABC’s of RSI Indications</h2>
          <h3>Airway protection</h3>
          <p>
            Airway protection, or the lack of airway protection, refers to those
            patients who are altered or at risk for aspiration. Examples include
            upper GI bleeds, large strokes, traumatic intracranial hemorrhages,
            etc. Aspiration events can lead to complications such as pneumonia
            or pneumonitis leading to respiratory failure which could be
            prevented with endotracheal intubation.
          </p>
          <p>
            The patient who is obtunded or confused may be at risk for airway
            collapse. For example, the patient who is requiring frequent or
            continuous jaw thrust to maintain a patent airway should be
            intubated because of their risk of apnea from airway obstruction -
            the obtunded patient is discussed in more detail below - see the
            section on Consciousness.
          </p>

          <h3>Breathing </h3>
          <p>
            The failure to ventilate or oxygenate leading to hypercapnic or
            hypoxic respiratory failure is another indication for RSI (e.g. CHF
            exacerbation, COPD, or asthma exacerbation). In the emergent
            setting, evaluating a patient's ability to ventilate or oxygenate is
            made on clinical grounds. Look at the patient. Is their breathing
            labored and is there a concern that they will tire out from their
            breathing? If so, then consider early endotracheal intubation.
          </p>
          <p>
            Adjuncts to your exam can also help determine a patient's
            ventilation and oxygenation status - pulse oximetry, continuous
            capnography, blood gases, etc. Temporary measures such as the
            delivery of oxygen through a nasal cannula, face mask, or even
            positive pressure ventilation can be used to stabilize the patient.
            Sometimes these interventions may prevent a patient's deterioration.
            But in general, patients who need assisted or positive pressure
            ventilation, in order to ventilate and oxygenate adequately, will
            likely require intubation, or at least it should be strongly
            considered.
          </p>

          <h3>Consciousness (Level of Consciousness)</h3>
          <p>
            A depressed level of consciousness is a risk for airway
            deterioration. Patients who are confused, lethargic, or unresponsive
            should prompt the clinician to assess for the patient’s ability to
            protect or maintain their airway. Regardless of the etiology, these
            patients may have difficulty protecting their airway, and placement
            of an endotracheal tube may be necessary to maintain a patent/open
            airway. Take, for example, a patient who presents for confusion
            secondary to a large stroke. This stroke could lead to neurological
            compromise and collapse of the airway. This patient would benefit
            from intubation in order to maintain airway patency.
          </p>
          <p>
            There are different ways to assess airway protection. Historically,
            assessing a gag reflex was thought to be a reliable indicator of the
            patient’s ability to protect their airway. However, the gag reflex
            is absent in about 12-25% of normal adults. A better way to evaluate
            a person's ability to maintain and protect their airway is to
            evaluate the patient's level of consciousness and their ability to
            speak to you. Do they follow commands? Are they unconscious?
            Additionally, the clinician would want to observe the patient
            swallow and tolerate oral secretions. If the patient is unable to do
            this, then the patient is likely not maintaining a safe and patent
            airway.
          </p>
          <p>
            In contrast to the confused/lethargic patient, also consider the
            combative and agitated patients and patients presenting with
            seizures. These patients are often difficult to assess because of
            the risk of injury they pose to care providers and themselves.
            Sedation, paralysis, and endotracheal intubation may be necessary to
            perform a physical exam and to allow the care providers to obtain
            necessary studies such as laboratory blood work, imaging, etc.
          </p>
          <p>
            A patient who is seizing or in status epilepticus is a good example
            of a case that likely will need intubation. This allows the
            clinician to provide medications that sedate the patient and takes
            away their respiratory drive, but also are used to treat the seizure
            itself (e.g. high doses of benzodiazepines).
          </p>

          <h3>Disability</h3>
          <p>
            Traumatic injuries or exposures can lead to airway compromise such
            as in cases of traumatic brain injuries and burns. Burns to the
            airway or face can cause airway edema and swelling leading to rapid
            airway obstruction.
          </p>
          <p>
            The classic example of traumatic cases requiring intubation is in
            those with a Glasgow Coma Scale less than 8 because of the risk of
            aspiration or loss of airway patency. Another example would be in
            cases of head trauma leading to increased intracranial pressure
            (ICP). Mechanical ventilation allows the clinician to target an
            appropriate PaCO2, (target PaCO2 35-40 mmHg), in an attempt to
            decrease ICP, but also provides airway protection as these patients
            are at high risk for aspiration.
          </p>

          <h3>Contraindications</h3>
          <p>
            Contraindications to RSI are few and relative. The anticipation of
            the difficult airway or impossible rescue oxygenation is a common
            contraindication. For example, in cases of facial trauma, the
            clinician should proceed with caution or consider alternative airway
            management strategies as there may be severe anatomical disruption
            leading to difficult or improperly placed endotracheal tubes.
            Alternatively, the clinician may consider an “awake” intubation
            approach in patients who might not tolerate apnea very well, because
            of profound hypoxemia or metabolic acidosis.
          </p>

          <h2>Summary</h2>
          <p>
            RSI is simply the steps a clinician takes to rapidly secure an
            airway involving the use of medications to sedate and paralyze the
            patient in order to try to create the most perfect conditions for
            intubation. Consider RSI in patients who fail to maintain or protect
            their airway, fail to ventilate or oxygenate, and in patients whose
            clinical course you expect to have a high likelihood of clinical
            deterioration.
          </p>
          <StyledLine top />
          <div className="recommended">
            <div className="recommended-label">RECOMMENDED READING</div>
            <RecommendedArticleContainer to="airway/rsi-process">
            <div className="content-label">ARTICLE</div>
              <h2>
                Seven P's of RSI - The Process of Rapid Sequence Intubation
              </h2>
              <p>
                RSI involves the use of an induction agent, followed immediately
                by a neuromuscular blocking agent to quickly create an optimal
                environment for intubation.
              </p>
            </RecommendedArticleContainer>
            <RecommendedArticleAnchor href="https://amzn.to/2WEky5N">
              <div className="content-label">AFFILIATE LINK</div>
              <h2>The ICU Book</h2>
              <p>
                A fundamental and respected resource in critical care. Including
                a review of various common critical care pathology, ariway and
                mechnical ventilation.
              </p>
            </RecommendedArticleAnchor>
            <RecommendedArticleContainer to="airway/choosing-induction-agent">
            <div className="content-label">ARTICLE</div>
              <h2>Choosing an Induction Agent</h2>
              <p>
                Induction agents used in rapid sequence intubation all lead to a
                similar endpoint which includes providing sedation and amnesia
                and can improve intubating conditions.
              </p>
            </RecommendedArticleContainer>
            <RecommendedArticleAnchor href="https://amzn.to/2ZaAj6d">
              <div className="content-label">AFFILIATE LINK</div>
              <h2>The Ventilator Book</h2>
              <p>
                A concise airway and mechanical ventilation reference for physicians, medical students, advanced practice providers, nurses, and respiratory therapists. The resource reviews the basics of respiratory failure and mechanical ventilation.
              </p>
            </RecommendedArticleAnchor>
          </div>
          <StyledLine />
        </Layout>
      </AirwayContainer>
    </>
  )
}

const AirwayContainer = styled.div`
  padding: 0 15px 15px 15px;
  max-width: 1000px;
  margin: auto;
  .recommended {
    .recommended-label {
      font-size: 14px;
      margin-top: 0;
      margin-bottom: 35px;
      font-weight: 500;
      font-family: futura-pt, sans-serif;
    }
    .content-label {
      font-family: futura-pt, sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
      color: gray;
    }
  }
`
const RecommendedArticleAnchor = styled.a`
  text-decoration: none;
  box-shadow: none;
  display: block;
  color: black;
  max-width: 1000px;
  margin: auto;
  margin-bottom: 45px;
  h2 {
    font-size: 21px;
    margin-top: 0;
    margin-bottom: 8px;
  }
  p {
    font-size: 18px;
    line-height: 130%;
    margin-top: 0;
  }
`

const RecommendedArticleContainer = styled(Link)`
  text-decoration: none;
  box-shadow: none;
  display: block;
  color: black;
  max-width: 1000px;
  margin: auto;
  margin-bottom: 45px;
  h2 {
    font-size: 21px;
    margin-top: 0;
    margin-bottom: 8px;
  }
  p {
    font-size: 18px;
    line-height: 130%;
    margin-top: 0;
  }
`

const StyledLine = styled.hr`
  margin-top: ${props => (props.top ? "100px" : "50px")};
`
